import * as React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Checkbox from '@mui/material/Checkbox';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import RoutesSystem  from "./routes/index";
import { Provider } from 'react-redux';
import { StoreApp } from './store';
import { GlobalComponents } from './components/globalComponents';
import * as yup from "yup";
import { LocaleYupValidation } from './utils/constant';

yup.setLocale(LocaleYupValidation);

const theme = createTheme({
  palette:{
    primary:{
      main:"#273240"
    },
    secondary:{
      main:"#0071aa"
    }
  }
});

function App() {
  return (
   
    <Provider store={StoreApp} >
      
       <ThemeProvider theme={theme}  >
       <GlobalComponents>
          <RoutesSystem />
       </GlobalComponents>
          
        </ThemeProvider>

  </Provider>


 );
}

export default App;
