import React from "react";
import { Autocomplete, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { red } from "@mui/material/colors";


const CustomTextFeld = ({errorMessage='', with_error=true,...props})=><FormControl
fullWidth
>
    <TextField size="small" error={Boolean(errorMessage)}  {...props} />
    {with_error && <FormHelperText error={Boolean(errorMessage)} >{errorMessage}</FormHelperText> }  
</FormControl>

const CustomSelectInput = ({errorMessage='',items=[],...props})=><FormControl
fullWidth
>
    <InputLabel id={props.name}  >{props.label}</InputLabel>
   <Select
   size="small"
    labelId={props.name}
   {...props}
   >
    {
        items.map(item=><MenuItem key={item.value} value={item.value} >{item.label}</MenuItem>)
    }
   </Select>
    <FormHelperText error={Boolean(errorMessage)} >{errorMessage}</FormHelperText>
</FormControl>


const CustomAutocomplete = ({errorMessage='',items=[],...props})=>{
    return (
       <FormControl error={Boolean(errorMessage)} >
          <Typography variant="p" >Listado de planes</Typography>
           <Autocomplete 
          
            
            options={items}
            {...props}
            onChange={(e,val)=>{
               props.onChange(val);
               }}
            renderInput={(props)=><TextField fullWidth {...props} />}
            />
            <Typography sx={{color:red[500],fontSize:'12px'}} >{errorMessage}</Typography>
       </FormControl>
      )
}

export  {
    CustomTextFeld,
    CustomSelectInput,
    CustomAutocomplete
}