import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import { CiclosCobro, DialogAction, Monedas, TypeDocuments } from "../utils/constant"
import { CustomAutocomplete, CustomSelectInput, CustomTextFeld } from "./custom-inputs"
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { AxiosInstance } from "../utils/AxiosInstance";



const schema = yup.object({
    nombres_cliente:yup.string().required(),
    apellidos_cliente:yup.string().required(),
    correo_cliente:yup.string().email().required(),
    telefono_cliente:yup.number().required(),
    moneda_id_cliente:yup.string().oneOf(Object.keys(Monedas)).required(),
    tipo_documento:yup.string().oneOf(Object.keys(TypeDocuments)).required(),
    numero_documento:yup.number().required(),
    planes:yup.array().required()

});

export const DialogClientes = ({
    dialogConfig={},
    onCloseDialog,
    onSendClientes,
    planesList=[]
})=>{

    const { control, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues:dialogConfig?.default_values || {planes:[]}
    });






    const onSubmitForm = (data)=>{
        data.planes = data.planes.map( item=>item.value );
        onSendClientes(data);
    }
 
    return (
        <Dialog
        open={dialogConfig?.opened}
        >
            
            <DialogContent>
                <DialogTitle>{dialogConfig.actionType === DialogAction.crear?'Crear cliente':'Editar cliente'} </DialogTitle>
                <form onSubmit={handleSubmit(onSubmitForm)} >
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={12} md={12} >
                            <Controller
                            control={control}
                            name="nombres_cliente"
                            render={({field})=><CustomTextFeld 
                                type='text'
                                label='Nombres' 
                                fullwidth={true}
                                {...field}
                                errorMessage={errors[field.name]?.message}
                            />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} >
                            <Controller
                            control={control}
                            name="apellidos_cliente"
                            render={({field})=><CustomTextFeld 
                                type='text'
                                label='Apellidos' 
                                fullwidth={true}
                                {...field}
                                errorMessage={errors[field.name]?.message}
                            />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} >
                            <Controller
                            control={control}
                            name="correo_cliente"
                            render={({field})=><CustomTextFeld 
                                type='email'
                                label='Correo' 
                                fullwidth={true}
                                {...field}
                                errorMessage={errors[field.name]?.message}
                            />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} >
                            <Controller
                            control={control}
                            name="telefono_cliente"
                            render={({field})=><CustomTextFeld 
                                type='phone'
                                label='Telefono' 
                                fullwidth={true}
                                {...field}
                                errorMessage={errors[field.name]?.message}
                            />}
                            />
                        </Grid>
                        
                        <Grid item xs={6} sm={6} md={6} >
                            <Controller
                            control={control}
                            name="moneda_id_cliente"
                            render={({field})=><CustomSelectInput 
                                type='text'
                                label='Moneda' 
                                items={ Object.keys(Monedas).map(keyCiclo=>({label:Monedas[keyCiclo],value:keyCiclo})) }
                                fullwidth={true}
                                
                                {...field}
                                errorMessage={errors[field.name]?.message}
                            />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} >

                        </Grid>
                        <Grid item xs={3} sm={3} md={3} >
                            <Controller
                            control={control}
                            name="tipo_documento"
                            render={({field})=><CustomSelectInput 
                                type='text'
                                label='Tipo de documento' 
                                items={ Object.keys(TypeDocuments).map(keyCiclo=>({label:TypeDocuments[keyCiclo],value:keyCiclo})) }
                                fullwidth={true}
                               
                                {...field}
                                errorMessage={errors[field.name]?.message}
                            />}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} >
                            <Controller
                            control={control}
                            name="numero_documento"
                            render={({field})=><CustomTextFeld 
                                type='number'
                                label='Numero de documento' 
                                fullwidth={true}
                                {...field}
                                errorMessage={errors[field.name]?.message}
                            />}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} >
                            <Controller
                            control={control}
                            name="planes"
                            render={({field})=>{
                              
                                return <CustomAutocomplete 
                                multiple={true}
                                fullwidth={true}
                                disablePortal
                                items={planesList}
                                sx={{width:300}}
                                size="small"
                                {...field}
                                errorMessage={errors[field.name]?.message}
                            />

                            }}
                            />
                        </Grid>

                    </Grid>
                    <DialogActions>
                      <Button onClick={onCloseDialog} variant="contained" size="small" color="secondary"  >Cancelar</Button>
                        <Button  type="submit"  variant="contained" size="small" color="primary"  >Guardar</Button>
                    </DialogActions>
                </form>
            </DialogContent>

        </Dialog>
    )

}