import { Alert, Button, Card, CardContent, Chip, Dialog, DialogActions, DialogContent, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { CiclosCobro, DialogAction, FormaterNumer } from "../utils/constant";



export const DialogShowClient = ({
    opened,
    onCloseDialog,
    clientData={},
    SolicitarCambioTarjeta,
    ReactivarPlan,
    CancelarPlan,
    onAddPlan
})=>{



    const getPlanStatus = status=>{
        if (status === 'POR-CONFIRMAR'){
            return <Alert severity="warning" >Pendiente</Alert>
        }
        if (status === 'activo'){
            return <Alert severity="success" >Activo</Alert>
        }
        if (status === 'ERROR'){
            return <Alert severity="error" >Con Error</Alert>
        }
        if (status === 'inactivo'){
            return <Alert severity="info"  >Inactivo</Alert>
        }
    }



    return (
        <Dialog
        open={opened}
        >
            <DialogContent>
                <Grid container spacing={1} >
                    <Grid item sm={12} sx={12} >
                        <Typography variant="h6" >
                            Datos  del cliente
                        </Typography>
                    </Grid>
                    <Grid item sm={12} sx={12} >
                        <Stack direction={"column"} >
                            <Typography variant="p" ><strong>Nombres:</strong> {clientData.nombres_cliente} {clientData.apellidos_cliente}</Typography>
                            <Typography variant="p" ><strong>Identificacion:</strong> {clientData.tipo_documento} {clientData.numero_documento}</Typography>
                            <Typography variant="p" ><strong>Telefono:</strong> {clientData.telefono_cliente}</Typography>
                            <Typography variant="p" ><strong>Correo:</strong> {clientData.correo_cliente}</Typography>
                            <Typography variant="p" ><strong>Moneda:</strong> {clientData.cod_moneda}</Typography>
                            
                        </Stack>
                    </Grid>
                    <Grid item sm={6} sx={6} md={6} >
                        <Typography variant="h6" fontSize='18px' >
                            Listado de suscripciones
                        </Typography>
                        
                    </Grid>
                    <Grid item sm={6} sx={6} md={6} >
                        <Button onClick={onAddPlan} variant="contained" size="small" color="primary" >Agrega Suscripción</Button>
                    </Grid>

                    {
                        clientData?.suscripciones?.map(item=>
                    <Grid key={item.clientes_id+"-"+item.id_plan} item sm={12} sx={12} >
                    
                     
                        <Card>
                            <CardContent>
                                <Typography variant="h6" fontSize={'15px'} >{item.nombre_plan}</Typography>
                                <Typography variant="p" fontSize={'14px'} >{ FormaterNumer(item.valor_plan)} {clientData.cod_moneda} X {CiclosCobro[item.ciclo_cobro_plan]} </Typography>
                                {getPlanStatus(item.status)}
                                <Button  onClick={e=>ReactivarPlan(item.clientes_id,item.id_plan)}  color="secondary" disabled={item.status !== 'ERROR'} variant="contained" size="small" >Reactivar</Button>
                                <Button onClick={e=>CancelarPlan(item.clientes_id,item.id_plan)} color="primary" disabled={item.status !== 'activo'} variant="contained" size="small" >Cancelar</Button>
                            </CardContent>
                        </Card>
                  
                    </Grid>
                    )
                }

                <Grid item sm={6} sx={6} >
                    <Typography variant="h6" fontSize='18px' >
                           Metodos de pago
                    </Typography>
                        
                </Grid>
                <Grid item sm={6} sx={6} >
                    <Button onClick={e=> SolicitarCambioTarjeta(clientData.id)} variant="contained" size="small" color="warning" >Solicitar cambio de tarjeta</Button>
                </Grid>
                <Grid item sm={12} sx={12} >
                {
                        clientData?.metodos_pago?.map(item=>
                    <Grid key={item.id_tarjeta} item sm={12} sx={12} >
                    
                     
                        <Card>
                            <CardContent>
                                <Typography variant="h6" fontSize={'15px'} >{item.mascara} - {item.franquicia}</Typography>
                                {getPlanStatus(item.estado)}
                               </CardContent>
                        </Card>
                  
                    </Grid>
                    )
                }
                        
                </Grid>

                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseDialog} variant="contained" color="primary" >OK</Button>
            </DialogActions>

        </Dialog>
    )
}