import { useDispatch, useSelector } from "react-redux";
import { DialogApp } from "./DialogApp";
import { Backdrop, CircularProgress } from "@mui/material";



export const GlobalComponents = ({children})=>{

    const dispatch = useDispatch();
    const {app:appState} = useSelector( app=>app );
    console.log({appState});


    return (
        <>

        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10000 }}
        open={appState.isLoading}
        
        >
        <CircularProgress color="inherit" />
        </Backdrop>
        <DialogApp {...appState.dialogInfo} />
        {children}
        </>
    )
}