import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import { CiclosCobro, DialogAction, Monedas } from "../utils/constant"
import { CustomSelectInput, CustomTextFeld } from "./custom-inputs"
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";



const schema = yup.object({
    nombre_plan:yup.string().required(),
    descripcion_plan:yup.string().required(),
    ciclo_cobro_plan:yup.string().oneOf(Object.keys(CiclosCobro)).required(),
    dias_prueba:yup.number().min(1).required(),
    moneda:yup.string().oneOf(Object.keys(Monedas)).required(),
    es_comercial:yup.bool().optional().notRequired().nullable(),
    valor_plan:yup.number().required()
});

export const DialogPlan = ({
    dialogConfig={},
    onCloseDialog,
    onSendPlan
})=>{

    const { control, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues:dialogConfig?.default_values || {}
    });


    const onSubmitForm = (data)=>{
        onSendPlan(data);
    }
    console.log({errors});
    return (
        <Dialog
        open={dialogConfig?.opened}
        >
            
            <DialogContent>
                <DialogTitle>{dialogConfig.actionType === DialogAction.crear?'Crear plan':'Editar plan'} </DialogTitle>
                <form onSubmit={handleSubmit(onSubmitForm)} >
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={12} md={12} >
                            <Controller
                            control={control}
                            name="nombre_plan"
                            render={({field})=><CustomTextFeld 
                                type='text'
                                label='Nombre' 
                                fullwidth={true}
                                {...field}
                                errorMessage={errors[field.name]?.message}
                            />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} >
                            <Controller
                            control={control}
                            name="descripcion_plan"
                            render={({field})=><CustomTextFeld 
                                type='text'
                                label='Descripcion' 
                                fullwidth={true}
                                {...field}
                                errorMessage={errors[field.name]?.message}
                            />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} >
                            <Controller
                            control={control}
                            name="ciclo_cobro_plan"
                            render={({field})=><CustomSelectInput 
                                type='text'
                                label='Ciclo de cobro' 
                                items={ Object.keys(CiclosCobro).map(keyCiclo=>({label:CiclosCobro[keyCiclo],value:keyCiclo})) }
                                fullwidth={true}
                                {...field}
                                errorMessage={errors[field.name]?.message}
                            />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} >
                            <Controller
                            control={control}
                            name="dias_prueba"
                            render={({field})=><CustomTextFeld 
                                type='number'
                                label='Dias de prueba' 
                                fullwidth={true}
                                {...field}
                                errorMessage={errors[field.name]?.message}
                            />}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} >
                        <Controller
                            control={control}
                            name="valor_plan"
                            render={({field})=><CustomTextFeld 
                                type='number'
                                label='Valor del plan' 
                                fullwidth={true}
                                {...field}
                                errorMessage={errors[field.name]?.message}
                            />}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} >
                            <Controller
                            control={control}
                            name="moneda"
                            render={({field})=><CustomSelectInput 
                                type='text'
                                label='Moneda' 
                                items={ Object.keys(Monedas).map(keyCiclo=>({label:Monedas[keyCiclo],value:keyCiclo})) }
                                fullwidth={true}
                                {...field}
                                errorMessage={errors[field.name]?.message}
                            />}
                            />
                        </Grid>

                    </Grid>
                    <DialogActions>
                      <Button onClick={onCloseDialog} variant="contained" size="small" color="secondary"  >Cancelar</Button>
                        <Button  type="submit"  variant="contained" size="small" color="primary"  >Guardar</Button>
                    </DialogActions>
                </form>
            </DialogContent>

        </Dialog>
    )

}