import { Button, Grid, InputAdornment, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import React, { useState } from "react";
import { DialogPlan } from "../components/DialogPlan";
import { CiclosCobro, DialogAction } from "../utils/constant";
import { AccountCircle, Search } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { setDialogInfo, setLoading } from "../store/appReducer";
import { AxiosInstance } from "../utils/AxiosInstance";


const filterBase = {
    search:null,
    rows_per_page:1000,
    page_number:1
}

export const Planes = ()=>{

    const [dataSet,setDataSet] = useState([]);
    const [planDialog,setPlanDialog] = useState({});
    const dispatch = useDispatch();
    const [filter,setFilter] = useState(filterBase);

   const rowsDefinition = [
    {
        key:"id_plan",
        keyValue:"id_plan",
        label:"PLAN ID",
        RenderBody:(props)=>props.value
    },
    {
        key:"nombre_plan",
        keyValue:"nombre_plan",
        label:"Nombre",
        RenderBody:(props)=>props.value
    },
    {
        key:"descripcion_plan",
        keyValue:"descripcion_plan",
        label:"Descripcion",
        RenderBody:(props)=>props.value
    },
    {
        key:"valor_plan",
        keyValue:"valor_plan",
        label:"Valor",
        RenderBody:(props)=>props.value
    },
    {
        key:"ciclo_cobro_plan",
        keyValue:"ciclo_cobro_plan",
        label:"Frecuencia",
        RenderBody:(props)=>CiclosCobro[props.value]
    },
    {
        key:"dias_prueba",
        keyValue:"dias_prueba",
        label:"Dias de prueba",
        RenderBody:(props)=>props.value
    },
    {
        key:"acciones",
        keyValue:"cod",
        label:"Acciones",
        RenderBody:(props)=>null
    }
   ];

   React.useEffect(()=>{
    loadData();
   },[]);

   React.useEffect(()=>{
    loadData(false);
   },[filter]);


   const onNewPlanDialog = ()=>{
    console.log("test buttom");
    setPlanDialog({
        opened:true,
        actionType:DialogAction.crear,
        default_values:{}
    });
   }

   const loadData = (has_load)=>{
    has_load && dispatch(setLoading(true));

        AxiosInstance.post("/planes/listar-planes",filter).then(({data})=>{
           setDataSet(data.value);
        }).catch(err=>{
            dispatch(setDialogInfo({ opened:true,
                type:'info',//error,info,question,sucessfull,
                title:'Error',//titulo del dialogo
                content:'Error al realizar la consulta',//contenido del dialogo
                }));
    
        }).finally(()=>{
            dispatch(setLoading(false));

        });
   }

   const onSendPlan = (payload)=>{
    dispatch(setLoading(true));

    AxiosInstance.post("/planes/crear-plan",payload).then(({data})=>{

        if (data.status){
            console.log({data});
            loadData();
        }else{
            dispatch(setDialogInfo({ opened:true,
                type:'info',//error,info,question,sucessfull,
                title:'Error',//titulo del dialogo
                content:data.msg,//contenido del dialogo
                }));
        }
      
    }).catch(err=>{
     
        dispatch(setDialogInfo({ opened:true,
            type:'info',//error,info,question,sucessfull,
            title:'Error',//titulo del dialogo
            content:'Error al realizar la consulta',//contenido del dialogo
            }));

    }).finally(()=>{
        dispatch(setLoading(false)); 
        setPlanDialog({});
    });

   }
    
   console.log({
    planDialog
   });
    return (
       <>
       <DialogPlan 
       dialogConfig={planDialog}
        onSendPlan={onSendPlan}
        onCloseDialog={e=>setPlanDialog({})}

       />
       
       <Grid container spacing={1} >
            <Grid item xs={12} md={3} sm={12} >
            <TextField onChange={e=>setFilter({...filter,search:e.target.value})} size="small" label='Buscar plan'   InputProps={{
            endAdornment:<InputAdornment position="start">
                    <Search />
            </InputAdornment>
            
            }}    />
            </Grid>
            <Grid item xs={12} md={12} sm={12} >
            <Button onClick={onNewPlanDialog}  variant="contained" size='small' >Agregar nuevo plan</Button>
            </Grid>
            <Grid item xs={12} md={12} sm={12} >

                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                rowsDefinition.map( item=><TableCell key={item.key} >{item.label}</TableCell> )
                            }
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            dataSet.map( dataItem=><TableRow 
                            key={dataItem.id_plan}
                            >
                                {
                                    rowsDefinition.map(Head_item=><TableCell key={Head_item.key} >
                                        <Head_item.RenderBody  value={dataItem[Head_item.keyValue]} />
                                    </TableCell>)
                                }
                            </TableRow> )
                        }
                    </TableBody>
                </Table>
            
            </Grid>
        </Grid>
       </>
    )

}