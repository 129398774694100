import styled from "@emotion/styled";
import { Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { CustomTextFeld } from "../components/custom-inputs";
import Logo from "../assets/img/chatico_logo_png.png";
import {useForm} from  "react-hook-form";
import  * as yup from "yup";
import { Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { DialogInfo, LocaleYupValidation, tokenSesion } from "../utils/constant";
import { AxiosInstance } from "../utils/AxiosInstance";
import { useDispatch } from "react-redux";
import { setDialogInfo, setLoading } from "../store/appReducer";
import {useNavigate} from "react-router-dom";

yup.setLocale(LocaleYupValidation);

const LoginContainer = styled.form`
width:auto;
height:auto;
`
const ButtonContainer = styled.div`
display:flex;

`;
const LogoBasico = styled.img`
width:150px;
margin-left:auto;
margin-right:auto;

`;

const LoginFormSchema = yup.object({
    email: yup.string().email().required(),
    clave: yup.string().required(),
  }).required();

export const Login = (props)=>{

    const dispatch = useDispatch();

    const { control, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(LoginFormSchema),
        defaultValues:{}
    });
    const navigate = useNavigate();


    React.useEffect(()=>{
         const token = localStorage.getItem(tokenSesion);
         
         if (token){
            navigate("/admin");
         }
    },[]);
    const onSubmitForm = (payload)=>{
        console.log({payload});
        dispatch(setLoading(true));
        AxiosInstance.post("/usuarios/iniciar-sesion",{
            correo_usuario:payload.email,
            clave_usuario:payload.clave
        }).then(({data})=>{

            if ( data.status ){
                localStorage.setItem(tokenSesion,data.value);
                navigate("/admin");
            }else{
                dispatch(setDialogInfo({ opened:true,
                    type:'info',//error,info,question,sucessfull,
                    title:'Datos incorrectos',//titulo del dialogo
                    content:'Usuario o contraseña no validos',//contenido del dialogo
                    }));
            }
          

        }).catch(err=>{
            
            if ( err.response?.status === 400 ){
                dispatch(setDialogInfo({ opened:true,
                    type:'info',//error,info,question,sucessfull,
                    title:'Datos incorrectos',//titulo del dialogo
                    content:'Usuario o contraseña no validos',//contenido del dialogo
                    }));
    
            }else{

                dispatch(setDialogInfo({ opened:true,
                    type:'error',//error,info,question,sucessfull,
                    title:'Error de servicio',//titulo del dialogo
                    content:'Vuelva a intentarlo',//contenido del dialogo
                   }));

            }
          
        }).finally(()=>{
            dispatch(setLoading(false));
        });
    }
        console.log({errors});
    return (
        <Grid sx={{height:'100vh'}} container >
                <Grid sm={2} md={4} xs={2} >

                </Grid>
                <Grid sm={8} md={4} xs={8} display={"flex"} flexDirection={"column"} justifyContent={"center"} >

                  <form onSubmit={handleSubmit(onSubmitForm)} >
                  <LoginContainer>
                        
                      <center>
                      <LogoBasico 
                         src={Logo}
                        />
                      </center>
                        <Typography variant="h6" > 
                           Inicio de sesion
                        </Typography>
                        <Controller
                        name="email"
                        control={control}
                        render={ ({field})=>
                            <CustomTextFeld
                            type='email'
                            label='Correo electronico'
                            variant="standard"
                            errorMessage={errors[field.name]?.message}
                            {...field}
                            />
                        }
                        />
                      
                       <Controller
                       name="clave"
                       control={control}
                       render={({field})=> <CustomTextFeld
                        type='password'
                        label='Clave'
                        variant="standard"
                        errorMessage={errors[field.name]?.message}
                        {...field}
                        />}
                       />
                
                   </LoginContainer>
                  
                   <Stack  >
                   <Button type="submit" variant="contained" color="primary" >Iniciar sesion</Button>
                   </Stack>
                  </form>

                </Grid>
                <Grid sm={2} md={4} xs={2} >

                </Grid>
        </Grid>
    )

}