import {
    createBrowserRouter,
    Link,
    Route,
    RouterProvider,
    Routes,
  } from "react-router-dom";
import { Login } from "../views/Login";
import AdminTemplate from "../views/admin-template";
import { Planes } from "../views/planes";
import { Clientes } from "../views/clientes";
import { CompleteProcessClient } from "../views/complete-process-client";
import { CambiarMetodoPago } from "../views/cambiar-pago-cliente";
import { Suscripciones } from "../views/suscripciones";


  const router = createBrowserRouter([
    { path: "*", Component: Root },
  ]);
  
  export default function App() {
    return <RouterProvider router={router} />;
  }
  

 export  function Root() {
   
    return (
      <Routes>
       <Route path="/" element={<Login />} />
       <Route path="/complete-process/:token_chatiico" element={<CompleteProcessClient />} />
       <Route path="/cambiar-pago/:token_request" element={<CambiarMetodoPago />} />
       <Route path="admin" element={<AdminTemplate />}>
          <Route path="planes"  element={<Planes />} />
          <Route path="clientes"  element={<Clientes />} />
          <Route path="suscripciones"  element={<Suscripciones />} />
       </Route>
       
      </Routes>
    );
  }