import React, { useEffect, useState } from "react";
import {Button, Card, CardContent, Grid, Typography} from "@mui/material";
import styled from "@emotion/styled";
import Logo from "../assets/img/chatico_logo_png.png";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { CustomTextFeld } from "../components/custom-inputs";
import { setDialogInfo, setLoading } from "../store/appReducer";
import { AxiosInstance } from "../utils/AxiosInstance";

const HeaderChatiico = styled.div`
background:#273240;
height:100px;
color:white;
text-align:center;
border-top-left-radius: 6px;
border-top-right-radius: 6px;
`;
const ContainerWrapp = styled.div`
width:100%;
`;

const SectionContent = styled.div`
margin-top:10px;
width:100%;
display:flex;
flex-direction:column;
`;
const LogoBasico = styled.img`
width:150px;
margin-left:auto;
margin-right:auto;

`;
const ButtomContainer = styled.div`
display: flex;
    flex-direction: column;
    margin-top: 10px;
`;

const ContainerNotFound = styled.div`
width:300px;
display: flex;
flex-direction: column;

`;

const creditCardValidation = yup.object({
    cardNumber: yup.string().required(),
    cardExpYear: yup.string().min(4).max(4).required(),
    cardExpMonth: yup.string().min(2).max(2).required(),
    cardCvc: yup.string().min(3).max(3).required(),
  }).required();

export const CambiarMetodoPago = ()=>{

    const dispatch = useDispatch();
    const [isEnableToken,setIsEnableToken] = useState(false);
    const [clientData,setClientData] = useState({});

    const { control, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(creditCardValidation),
        defaultValues:{}
    });
    const navigate = useNavigate();
    const {token_request} = useParams();


    useEffect(()=>{

        if (token_request){
            comprobeTokenChatIcon();
        }

    },[]);

    const comprobeTokenChatIcon = ()=>{
        dispatch(setLoading(true));
        AxiosInstance.post("/planes/verify-method-change",{
            token_request
        }).then(({data})=>{

            if ( data.status ){
                setIsEnableToken(true);
                setClientData(data.value);

            }else{

            }

        }).catch(err=>{

            dispatch(setDialogInfo({ opened:true,
                type:'info',//error,info,question,sucessfull,
                title:'Error',//titulo del dialogo
                content:'Error de comunicacion con nuestros servicios',//contenido del dialogo
                }));
           
        }).finally(()=>{
            dispatch(setLoading(false));
        });
    }

    const onSubmitForm = (payload)=>{
        console.log({payload});
        dispatch(setLoading(true));
        AxiosInstance.post("/planes/change-method-change",{
            "token_request":token_request,
            "tarjeta_numero":payload.cardNumber,
            "tarjeta_anio":payload.cardExpYear,
            "tarjeta_mes":payload.cardExpMonth,
            "tarjeta_cvv":payload.cardCvc
        }).then(({data})=>{

            if ( data.status ){
                dispatch(setDialogInfo({ opened:true,
                    type:'sucessfull',//error,info,question,sucessfull,
                    title:'Proceso completo',//titulo del dialogo
                    content:'Usted ha finalizado su proceso de forma exitosa',//contenido del dialogo
                    }));
                setTimeout(()=>{
                    window.location = 'https://app.chatiico.com.co/';
                },4000);
            }else{
                dispatch(setDialogInfo({ opened:true,
                    type:'info',//error,info,question,sucessfull,
                    title:'Datos no validos',//titulo del dialogo
                    content:data.msg,//contenido del dialogo
                    }));
            }

        }).catch(error=>{
            dispatch(setDialogInfo({ opened:true,
                type:'info',//error,info,question,sucessfull,
                title:'Error',//titulo del dialogo
                content:'Error al tratar de finalizar el proceso vuelva a intentarlo',//contenido del dialogo
                }));
        }).finally(()=>{
            dispatch(setLoading(false));
        });
    }


    if (!isEnableToken){

        return <div
        style={{width:'100vw',height:'100vh',display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}
        >
            <ContainerNotFound>
                <center>
                      <LogoBasico 
                         src={Logo}
                        />
                           <Typography variant="h6" >
                                Su sesion no es válida
                            </Typography>
                            <Button  variant="contained" size="small" fullWidth >Contactar al administrador</Button>
                      </center>
             
            </ContainerNotFound>
        </div>
    }
    
    console.log({errors});
    return (
        <Grid container sx={{height:'100vh',padding:'10px'}}  >
            <Grid item xs={0} sm={0} md={3} >

            </Grid>
            <Grid display={"flex"} justifyContent={"center"} alignItems={"center"} item xs={12} sm={12} md={4} >

                <ContainerWrapp>
                    <HeaderChatiico>
                    <center>
                      <LogoBasico 
                         src={Logo}
                        />
                      </center>
                    </HeaderChatiico>
                   
                    <Typography variant="h6" >
                       Cambiar metodo de pago
                    </Typography>
                    <Typography>
                        Hola, {clientData.nombres_cliente? String(clientData.nombres_cliente).split(" ")[0]:null} Para cambiar tu metodo de pago digita los siguientes campos
                    </Typography>

                    <SectionContent>
                        <Typography variant="h6" >Datos basicos</Typography>
                        <Typography variant="p" ><strong>Nombres: </strong> {clientData.nombres_cliente}</Typography>
                        <Typography variant="p" ><strong>Apellidos: </strong>{clientData.apellidos_cliente}</Typography>
                        <Typography variant="p" ><strong>Correo: </strong>{clientData.correo_cliente}</Typography>
                        <Typography variant="p" ><strong>Documento: </strong>{clientData.tipo_documento}: {clientData.numero_documento}</Typography>
                    </SectionContent>
                    <SectionContent>
                    <form onSubmit={handleSubmit(onSubmitForm)} style={{width:'100%'}} >
                        <Card style={{background:"#d8d8d8"}} >
                            <CardContent>
                            <Grid container >
                                <Grid item xs={12} sm={12} md={12} >
                                <Controller
                                    name="cardNumber"
                                    control={control}
                                    render={ ({field})=>
                                        <CustomTextFeld
                                        type='string'
                                        label='Numero de la tarjeta'
                                        variant="standard"
                                        errorMessage={errors[field.name]?.message}
                                        {...field}
                                        />
                            }
                            />
                                </Grid>
                                <Grid item  xs={3} sm={3} md={2} >
                                    <Controller
                                        name="cardExpYear"
                                        control={control}
                                        render={ ({field})=>
                                            <CustomTextFeld
                                            placeholder='YYYY'
                                            type='string'
                                            label='Año'
                                            variant="standard"
                                            errorMessage={errors[field.name]?.message}
                                            with_error={false}
                                            {...field}
                                            />
                                        }
                                        />
                                </Grid>
                                <Grid item xs={3} sm={3} md={2} >
                                    <Controller
                                        name="cardExpMonth"
                                        control={control}
                                        render={ ({field})=>
                                            <CustomTextFeld
                                            type='string'
                                            label='Mes'
                                            placeholder='MM'
                                            variant="standard"
                                            errorMessage={errors[field.name]?.message}
                                            with_error={false}
                                            {...field}
                                            />
                                        }
                                        />
                                </Grid>
                                <Grid  xs={3} sm={3} item md={3} >

                                </Grid>
                                <Grid xs={3} sm={3} item md={2} >
                                <Controller
                                        name="cardCvc"
                                        control={control}
                                        render={ ({field})=>
                                            <CustomTextFeld
                                            type='string'
                                            label='CVC'
                                            placeholder='CVC'
                                            variant="standard"
                                            errorMessage={errors[field.name]?.message}
                                            with_error={false}
                                            {...field}
                                            />
                                        }
                                        />
                                </Grid>
                        </Grid>
                            </CardContent>
                        </Card>
                        <ButtomContainer>
                        <Button variant="contained" color="primary" type="submit" >Realizar cambio</Button>       
                  
                        </ButtomContainer>
                         </form>
                        
                    </SectionContent>


                </ContainerWrapp>

            </Grid>
            <Grid item xs={0} sm={0} md={3} >

            </Grid>
        </Grid>
    )
}