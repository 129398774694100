import moment from 'moment'
import "moment/locale/es"; // without this line it didn't work
/**
 * ty
 */

export const DialogInfo = {
    opened:false,
    type:'',//error,info,question,sucessfull,
    title:'',//titulo del dialogo
    content:'',//contenido del dialogo
    acciones:[],/*{
        label:"",
        color:"primary",
        action:()=>{}
    } */
     
}


moment.locale('es');


export const LocaleYupValidation = {
    mixed: {
        default: 'Campo no valido',
        required: 'El campo es obligatorio',
        oneOf: 'Debe colocar uno de los siguientes valores: ${values}',
        notOneOf: 'No puede ser ninguno de los valores: ${values}',
        notType: ({ type }) => (type === 'number' ? 'Debe ser un numero' : 'Campo no valido')
      },
      string: {
        length: 'Debe tener exactamente ${length} caracteres',
        min: 'Debe tener Minimo ${min} caracteres',
        max: 'Debe tener Maximo ${max} caracteres',
        email: 'E-mail no valido',
        url: 'La url no es valida',
        trim: 'No debe contener espacios',
        lowercase: 'Debe estar en minuscula',
        uppercase: 'Debe estar en  mayuscula'
      },
      number: {
        min: 'Debe ser minimo ${min}',
        max: 'No debe ser mayor a ${max}',
        lessThan: 'Debe ser menor que ${less}',
        moreThan: 'Debe ser mayor que ${more}',
        // notEqual: 'Não pode ser igual à ${notEqual}',
        positive: 'Debe ser un numero positivo',
        negative: 'Debe ser un numero negativo',
        integer: 'Debe ser un numero entero'
      },
      date: {
        min: 'Debe ser menor que ${min}',
        max: 'Debe ser mayor que ${max}'
      },
      array: {
        min: 'Debe tener minimo ${min} items',
        max: 'Debe tener máximo ${max} items'
      }
}

export const DialogAction = {
  editar:"EDITAR",
  crear:"CREAR"
}

export const CiclosCobro = {
  "day":"Diario",
  "week":"Semanal",
  "month":"Mensual",
  "year":"Anual"
}
export const Monedas = {
  "cop":"COP",
  "usd":"USD",
}

export const TypeDocuments = {
  "CC":"CC",
  "NIT":"NIT",
  "DNI":"DNI",
  "PPN":"PPN"
}
export const GetSuscripcionStatus = {
  "POR-CONFIRMAR":"Por confirmar",
  "activo":"Activo",
  "inactivo":"Inactivo",
  "ERROR":"Con error"
}


export const CalculatePaymentDay = (date_suscripcion,test_day,frecuency)=>{

  const cicleDays = {
    "day":1,
    "week":7,
    "month":30,
    "year":365
  };
  const numDadys = cicleDays[frecuency];
  const suscripcionDate = moment(date_suscripcion).add(test_day,"days");
  const now = moment().startOf("day");

  const diff = Math.abs( suscripcionDate.diff(now,"days") );
  const diffInDay = Math.abs(diff/numDadys);
  const diasTranscurridos = Math.abs(  diffInDay - (Number(diffInDay.toFixed("0") ) || 1) );
  console.log({diasTranscurridos,num:diffInDay.toFixed("0")});
  const totalDaysFalantes = Math.ceil( numDadys * diasTranscurridos );


  const fechaPago  = now.clone().add(totalDaysFalantes,"days").format('YYYY-MM-DD');
  console.log({date_suscripcion,diff,diasTranscurridos,diffInDay,totalDaysFalantes});

  return fechaPago;
}



export const FormaterNumer = (value)=>{
  return new Intl.NumberFormat("es-CO").format(value)
}

export const tokenSesion = "chatiico_app";