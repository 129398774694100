import { Button, Chip, Grid, InputAdornment, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import React, { useState } from "react";
import { DialogPlan } from "../components/DialogPlan";
import { CiclosCobro, DialogAction } from "../utils/constant";
import { AccountCircle, Email, Search, Visibility } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { setDialogInfo, setLoading } from "../store/appReducer";
import { AxiosInstance } from "../utils/AxiosInstance";
import { orange } from "@mui/material/colors";
import { DialogClientes } from "../components/DialogClientes";
import { DialogShowClient } from "../components/DialogShowClient";
import { DialogAddPlan } from "../components/DialogAddPlan";


const filterBase = {
    search:null,
    rows_per_page:1000,
    page_number:1
}



export const Clientes = ()=>{

    const [dataSet,setDataSet] = useState([]);
    const [planDialog,setPlanDialog] = useState({});
    const dispatch = useDispatch();
    const [filter,setFilter] = useState(filterBase);
    const [planes,setPlanes] = useState([]);
    const [showInfo,setShowInfo] = useState({});
    const [isOpenedAddPlan,setIsOpenedAddPlan] = useState(false);

   const rowsDefinition = [
    {
        key:"id",
        keyValue:"id",
        label:"ID",
        RenderBody:(props)=>props.value
    },
    {
        key:"nombres_cliente",
        keyValue:"nombres_cliente",
        label:"Nombres",
        RenderBody:(props)=>props.value
    },
    {
        key:"apellidos_cliente",
        keyValue:"apellidos_cliente",
        label:"Apellidos",
        RenderBody:(props)=>props.value
    },
    {
        key:"correo_cliente",
        keyValue:"correo_cliente",
        label:"Correo",
        RenderBody:(props)=>props.value
    },
    {
        key:"telefono_cliente",
        keyValue:"telefono_cliente",
        label:"Telefono",
        RenderBody:(props)=>props.value
    },
    {
        key:"suscripciones",
        keyValue:"suscripciones",
        label:"Suscripciones",
        RenderBody:({value})=><>
           {value.map(suscription=><Chip key={suscription.id_plan} color="primary" label={suscription.nombre_plan} />)}
        </>
    },
    {
        key:"estado",
        keyValue:"estado",
        label:"Estado",
        RenderBody:(props)=>{
            if (props.value === 'PENDIENTE'){
                return <Chip color="warning" label='Pendiente' />
            }
            if (props.value === 'ACTIVO'){
                return <Chip color="success" label='Activo' />
            }
            if (props.value === 'INACTIVO'){
                return <Chip color="red" label='Inactivo' />
            }
        }
    },
    {
        key:"acciones",
        keyValue:"id",
        label:"Acciones",
        RenderBody:({item,value})=><Stack direction="row" alignContent={"space-between"} justifyContent={"center"} >
            <Button size="small"  variant="contained" onClick={e=>{
                setShowInfo({opened:true,clientData:item});
             }} ><Visibility /></Button>
            <Button onClick={e=>onResetClientEmail(value)} size="small" variant="contained" ><Email /> </Button>
        </Stack>
    }
   ];

   React.useEffect(()=>{
    loadData();
    loadInitData();
   },[]);

   React.useEffect(()=>{
    loadData(false);
   },[filter]);


   const onResetClientEmail =(client_id)=>{
    dispatch(setLoading(true));

    AxiosInstance.post("/usuarios/resent-client-email",{
        client_id
    }).then(({data})=>{

        if ( data.status ){
            dispatch(setDialogInfo({ opened:true,
                type:'sucessfull',//error,info,question,sucessfull,
                title:'Email reenviado',//titulo del dialogo
                content:'Se le ha reenviado el correo al usuario',//contenido del dialogo
                }));
        }else{
            dispatch(setDialogInfo({ opened:true,
                type:'info',//error,info,question,sucessfull,
                title:'Error',//titulo del dialogo
                content:'Error al realizar la consulta',//contenido del dialogo
                }));
        }
      
    }).catch(err=>{
        dispatch(setDialogInfo({ opened:true,
            type:'info',//error,info,question,sucessfull,
            title:'Error',//titulo del dialogo
            content:'Error al realizar la consulta',//contenido del dialogo
            }));

    }).finally(()=>{
        dispatch(setLoading(false));

    });
   }


   const onNewPlanDialog = ()=>{
    console.log("test buttom");
    setPlanDialog({
        opened:true,
        actionType:DialogAction.crear,
        default_values:{}
    });
   }

   const loadData = (has_load)=>{
    has_load && dispatch(setLoading(true));

        AxiosInstance.post("/usuarios/listar-clientes",filter).then(({data})=>{
           setDataSet(data.value.data);
        }).catch(err=>{
            dispatch(setDialogInfo({ opened:true,
                type:'info',//error,info,question,sucessfull,
                title:'Error',//titulo del dialogo
                content:'Error al realizar la consulta',//contenido del dialogo
                }));
    
        }).finally(()=>{
            dispatch(setLoading(false));

        });
   }

   const onSendClientes = (payload)=>{
    console.log({payload});

    dispatch(setLoading(true));

    AxiosInstance.post("/usuarios/crear-cliente",payload).then(({data})=>{
        console.log({data});
        loadData();
    }).catch(err=>{
     
        dispatch(setDialogInfo({ opened:true,
            type:'info',//error,info,question,sucessfull,
            title:'Error',//titulo del dialogo
            content:'Error al realizar la consulta',//contenido del dialogo
            }));

    }).finally(()=>{
        dispatch(setLoading(false)); 
        setPlanDialog({});
    });

   }

   const loadInitData = ()=>{
    AxiosInstance.post("/planes/listar-planes")
    .then(({data})=>{

        setPlanes(data.value.map(ele=>({label:ele.nombre_plan,value:ele.id_plan})));

    }).catch(err=>{
        dispatch(setDialogInfo({ opened:true,
            type:'info',//error,info,question,sucessfull,
            title:'Error',//titulo del dialogo
            content:'Error al realizar la consulta',//contenido del dialogo
            }));
    });
}
    
   const closeDialogInfo = ()=>{
    setShowInfo({});
   }
  
   const ReactivarPlan = (cliente_id,plan_id)=>{
    dispatch(setLoading(true));

    AxiosInstance.post("/planes/reactivar-sub",{
        cliente_id,
        plan_id
    }).then(({data})=>{
        closeDialogInfo();
        if (data.status){
            dispatch(setDialogInfo({ opened:true,
                type:'sucessfull',//error,info,question,sucessfull,
                title:'Operacion exitosa',//titulo del dialogo
                content:data.msg,//contenido del dialogo
                }));   
                loadData(true);
        }else{
            dispatch(setDialogInfo({ opened:true,
                type:'info',//error,info,question,sucessfull,
                title:'Operacion no completada',//titulo del dialogo
                content:data.msg,//contenido del dialogo
                }));   
        }
    }).catch(err=>{
        dispatch(setDialogInfo({ opened:true,
            type:'info',//error,info,question,sucessfull,
            title:'Error',//titulo del dialogo
            content:'Error al realizar la peticion',//contenido del dialogo
            }));
    }).finally(()=>{
        dispatch(setLoading(false));
    });
       

   }

   const  SolicitarCambioTarjeta = (cliente_id)=>{

    dispatch(setLoading(true));

    AxiosInstance.post("/planes/solicitar-cambio-clave",{
        cliente_id,
    }).then(({data})=>{

        if (data.status ){
            dispatch(setDialogInfo({ opened:true,
                type:'sucessfull',//error,info,question,sucessfull,
                title:'Operacion exitosa',//titulo del dialogo
                content:data.msg,//contenido del dialogo
                }));  
        }else{
            dispatch(setDialogInfo({ opened:true,
                type:'info',//error,info,question,sucessfull,
                title:'Operacion no completada',//titulo del dialogo
                content:data.msg,//contenido del dialogo
                }));   
        }
        
          

    }).catch(err=>{
        dispatch(setDialogInfo({ opened:true,
            type:'info',//error,info,question,sucessfull,
            title:'Error',//titulo del dialogo
            content:'Error al realizar la peticion',//contenido del dialogo
            }));
    }).finally(()=>{
        dispatch(setLoading(false));
    });
   }


   const  CancelarPlan = (cliente_id,plan_id)=>{

    dispatch(setLoading(true));

    AxiosInstance.post("/planes/cancelar-sub",{
        cliente_id,
        plan_id
    }).then(({data})=>{

        if (data.status ){
            dispatch(setDialogInfo({ opened:true,
                type:'sucessfull',//error,info,question,sucessfull,
                title:'Operacion exitosa',//titulo del dialogo
                content:data.msg,//contenido del dialogo
                }));  
        }else{
            dispatch(setDialogInfo({ opened:true,
                type:'info',//error,info,question,sucessfull,
                title:'Operacion no completada',//titulo del dialogo
                content:data.msg,//contenido del dialogo
                }));   
        }
        
          

    }).catch(err=>{
        dispatch(setDialogInfo({ opened:true,
            type:'info',//error,info,question,sucessfull,
            title:'Error',//titulo del dialogo
            content:'Error al realizar la peticion',//contenido del dialogo
            }));
    }).finally(()=>{
        dispatch(setLoading(false));
    });
   }


   const onSubmitNewPlan = (payload)=>{
    

    AxiosInstance.post("/planes/add-plan-to-client",{...payload}).then(({data})=>{
        
        if (data.status ){
            dispatch(setDialogInfo({ opened:true,
                type:'sucessfull',//error,info,question,sucessfull,
                title:'Operacion exitosa',//titulo del dialogo
                content:data.msg,//contenido del dialogo
                }));  
                loadData(true);
        }else{
            dispatch(setDialogInfo({ opened:true,
                type:'info',//error,info,question,sucessfull,
                title:'Operacion no completada',//titulo del dialogo
                content:data.msg,//contenido del dialogo
                }));   
        }
        
          

    }).catch(err=>{
        dispatch(setDialogInfo({ opened:true,
            type:'info',//error,info,question,sucessfull,
            title:'Error',//titulo del dialogo
            content:'Error al realizar la peticion',//contenido del dialogo
            }));
    }).finally(()=>{
        dispatch(setLoading(false));
    });

   }

   const onOpenAddNewPlan = ()=>{
    setShowInfo({...showInfo,opened:false});
    setIsOpenedAddPlan(true);
   }
   const onCloseDialogAddPlan = ()=>{
    setIsOpenedAddPlan(false);
    setShowInfo({});
   }

    return (
       <>
       <DialogAddPlan
        clientData={showInfo.clientData}
        isOpened={isOpenedAddPlan}
        planList={planes}
        onCloseDialogAddPlan={onCloseDialogAddPlan}
        onSubmitNewPlan={onSubmitNewPlan}
       />
       <DialogShowClient
       {...showInfo}
       onCloseDialog={closeDialogInfo}
       ReactivarPlan={ReactivarPlan}
       SolicitarCambioTarjeta={SolicitarCambioTarjeta}
       CancelarPlan={CancelarPlan}
       onAddPlan={onOpenAddNewPlan}
       />
       <DialogClientes 
        dialogConfig={planDialog}
        onSendClientes={onSendClientes}
        onCloseDialog={e=>setPlanDialog({})}
        planesList={planes}
       />
       
       <Grid container spacing={1} >
            <Grid item xs={12} md={3} sm={12} >
            <TextField onChange={e=>setFilter({...filter,search:e.target.value})} size="small" label='Buscar cliente'   InputProps={{
            endAdornment:<InputAdornment position="start">
                    <Search />
            </InputAdornment>
            
            }}    />
            </Grid>
            <Grid item xs={12} md={12} sm={12} >
            <Button onClick={onNewPlanDialog}  variant="contained" size='small' >Agregar nuevo cliente</Button>
            </Grid>
            <Grid item xs={12} md={12} sm={12} >

                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                rowsDefinition.map( item=><TableCell key={item.key} >{item.label}</TableCell> )
                            }
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            dataSet.map( dataItem=><TableRow 
                            key={dataItem.id_plan}
                            >
                                {
                                    rowsDefinition.map(Head_item=><TableCell key={Head_item.key} >
                                        <Head_item.RenderBody  item={dataItem}  value={dataItem[Head_item.keyValue]} />
                                    </TableCell>)
                                }
                            </TableRow> )
                        }
                    </TableBody>
                </Table>
            
            </Grid>
        </Grid>
       </>
    )

}