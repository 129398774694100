import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { DialogAction, tokenSesion } from "../utils/constant";
import { useNavigate } from "react-router-dom";




export const DialogCloseSesion = ({
    opened,
    onCloseDialog
})=>{

    const navigation = useNavigate();

    const onCloseSesion = ()=>{
        localStorage.removeItem( tokenSesion );
        onCloseDialog();
        navigation("/",{
            replace:true,
            state:[]
        });
      
    }

    return (
        <Dialog
        open={opened}
        >
            <DialogContent>
                <DialogTitle>
                    Confirmar cierre de sesion
                </DialogTitle>
                <Typography variant="p" >
                  ¿Esta seguro que desea cerrar la sesion?
                </Typography>
                <DialogActions>
                    <Button onClick={onCloseDialog}  variant="contained" color="secondary" size="small" >No, Mantener</Button>
                    <Button onClick={onCloseSesion} variant="contained" color="primary" size="small" >Si, Cerrar</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}