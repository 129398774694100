import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading:false,
  usuarioData:{},
  dialogInfo:{}
}

export const appSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setLoading: (state,action) => {
    
      state.isLoading = action.payload;
    },
    setDialogInfo: (state,action) => {
      state.dialogInfo = action.payload
    },
    setUserData: (state, action) => {
      state.usuarioData += action.payload;
    },
  },
  
})

// Action creators are generated for each case reducer function
export const { setLoading, setDialogInfo, setUserData } = appSlice.actions;

export default appSlice.reducer