import styled from "@emotion/styled";
import { Error } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { setDialogInfo } from "../store/appReducer";
import { DialogInfo } from "../utils/constant";


const DialogIconContainer = styled.div`
display:flex;
justify-content:center;
align-items:center;
`

export const DialogApp = (props)=>{

    let IconDialog = <></>;
    const dispatch = useDispatch();

    if ( props.type === 'error'){
        IconDialog = <Error color="error" />
    }
    if ( props.type === 'warning'){
        IconDialog = <Error color="warning" />
    }
    if ( props.type === 'sucessfull'){
        IconDialog = <Error color="success" />
    }
    if ( props.type === 'sucessfull'){
        IconDialog = <Error color="success" />
    }
    return (
        <Dialog
        open={props.opened}
        >

           
            <DialogContent>
                <DialogIconContainer>
                    {IconDialog}
                </DialogIconContainer>
                <Typography variant="h6" >
                    {props.title}
                </Typography>
               {props.content}
            </DialogContent>
            <DialogActions>

            <Button size="small" onClick={e=>dispatch(setDialogInfo(DialogInfo))} color="primary" variant="contained" >OK</Button>
             
            </DialogActions>
        </Dialog>
    )
}