import { Button, Grid, InputAdornment, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import React, { useState } from "react";
import { DialogPlan } from "../components/DialogPlan";
import { CalculatePaymentDay, CiclosCobro, DialogAction, FormaterNumer, GetSuscripcionStatus } from "../utils/constant";
import { AccountCircle, Search } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { setDialogInfo, setLoading } from "../store/appReducer";
import { AxiosInstance } from "../utils/AxiosInstance";


const filterBase = {
    search:null,
    rows_per_page:1000,
    page_number:1
}
CalculatePaymentDay('2023-10-20',1,"week");
export const Suscripciones = ()=>{

    const [dataSet,setDataSet] = useState([]);
    const [planDialog,setPlanDialog] = useState({});
    const dispatch = useDispatch();
    const [filter,setFilter] = useState(filterBase);



   const rowsDefinition = [
    {
        key:"identificacion_cliente",
        keyValue:"identificacion_cliente",
        label:"CLIENTE ID",
        RenderBody:(props)=>props.value
    },
    {
        key:"nombres_cliente",
        keyValue:"nombres_cliente",
        label:"Nombre cliente",
        RenderBody:(props)=>props.data.nombres_cliente+" "+props.data.apellidos_cliente
    },
   /* {
        key:"correo_cliente",
        keyValue:"correo_cliente",
        label:"Correo",
        RenderBody:(props)=>props.value
    }, */
    {
        key:"valor_plan",
        keyValue:"valor_plan",
        label:"Valor del plan",
        RenderBody:(props)=>`${FormaterNumer(props.data.valor_plan)} ${props.data.cod_moneda}`
    },
    {
        key:"ciclo_cobro_plan",
        keyValue:"ciclo_cobro_plan",
        label:"Cilo de cobro",
        RenderBody:(props)=>CiclosCobro[props.value]
    },
    {
        key:"estado_suscripcion",
        keyValue:"estado_suscripcion",
        label:"Cilo de cobro",
        RenderBody:(props)=> GetSuscripcionStatus[props.value]
    },
    {
        key:"suscription_date",
        keyValue:"suscription_date",
        label:"Fecha de suscripcion",
        RenderBody:(props)=>props.value || 'N/A'
    },
     {
        key:"fecha_pago",
        keyValue:"fecha_pago",
        label:"Fecha de proximo pago",
        RenderBody:({data})=>CalculatePaymentDay(data.suscription_date,data.dias_prueba,data.ciclo_cobro_plan)
    }, 
 
   
   ];

   React.useEffect(()=>{
    loadData();
   },[]);

   React.useEffect(()=>{
    loadData(false);
   },[filter]);


   const onNewPlanDialog = ()=>{
    console.log("test buttom");
    setPlanDialog({
        opened:true,
        actionType:DialogAction.crear,
        default_values:{}
    });
   }

   const loadData = (has_load)=>{
    has_load && dispatch(setLoading(true));

        AxiosInstance.post("/planes/obtener-suscripciones",filter).then(({data})=>{
           setDataSet(data.value);
        }).catch(err=>{
            dispatch(setDialogInfo({ opened:true,
                type:'info',//error,info,question,sucessfull,
                title:'Error',//titulo del dialogo
                content:'Error al realizar la consulta',//contenido del dialogo
                }));
    
        }).finally(()=>{
            dispatch(setLoading(false));

        });
   }

   const onSendPlan = (payload)=>{
    dispatch(setLoading(true));

    AxiosInstance.post("/planes/crear-plan",payload).then(({data})=>{

        if (data.status){
            console.log({data});
            loadData();
        }else{
            dispatch(setDialogInfo({ opened:true,
                type:'info',//error,info,question,sucessfull,
                title:'Error',//titulo del dialogo
                content:data.msg,//contenido del dialogo
                }));
        }
      
    }).catch(err=>{
     
        dispatch(setDialogInfo({ opened:true,
            type:'info',//error,info,question,sucessfull,
            title:'Error',//titulo del dialogo
            content:'Error al realizar la consulta',//contenido del dialogo
            }));

    }).finally(()=>{
        dispatch(setLoading(false)); 
        setPlanDialog({});
    });

   }
    
   console.log({
    planDialog
   });
    return (
       <>
      
       
       <Grid container spacing={1} >
            <Grid item xs={12} md={3} sm={12} >
            <TextField onChange={e=>setFilter({...filter,search:e.target.value})} size="small" label='Buscar suscripcion'   InputProps={{
            endAdornment:<InputAdornment position="start">
                    <Search />
            </InputAdornment>
            
            }}    />
            </Grid>
           
            <Grid item xs={12} md={12} sm={12} >

                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                rowsDefinition.map( item=><TableCell key={item.key} >{item.label}</TableCell> )
                            }
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            dataSet.map( dataItem=><TableRow 
                            key={dataItem.id_plan}
                            >
                                {
                                    rowsDefinition.map(Head_item=><TableCell key={Head_item.key} >
                                        <Head_item.RenderBody data={dataItem}  value={dataItem[Head_item.keyValue]} />
                                    </TableCell>)
                                }
                            </TableRow> )
                        }
                    </TableBody>
                </Table>
            
            </Grid>
        </Grid>
       </>
    )

}