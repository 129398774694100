import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import { CiclosCobro, DialogAction, Monedas, TypeDocuments } from "../utils/constant"
import { CustomAutocomplete, CustomSelectInput, CustomTextFeld } from "./custom-inputs"
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { AxiosInstance } from "../utils/AxiosInstance";




export const DialogAddPlan = ({
    clientData = {},
    isOpened=false,
    planList=[],
    onCloseDialogAddPlan=()=>{},
    onSubmitNewPlan
    
})=>{
    console.log({clientData});
    const [selectedPlan,setSelectedPlan] = useState(null);


    const onSend = ()=>{
        if ( selectedPlan){
            onSubmitNewPlan({
                plan_id:selectedPlan,
                cliente_id:clientData.id
            });
        }
       
    }
   
    return (
        <Dialog
        open={isOpened}
        >
            
            <DialogContent>
                <DialogTitle>Añadir plan</DialogTitle>
                <Grid container >
                    <Grid item xs={12} sm={12} md={12} >
                        Cliente: {clientData.nombres_cliente} {clientData.apellidos_cliente}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} >
                        <InputLabel>Seleccione el plan</InputLabel>
                        <Select
                        fullWidth
                        onChange={e=>setSelectedPlan(e.target.value)}
                        >
                            {
                                planList.map( item=><MenuItem key={item.value} value={item.value} >{item.label}</MenuItem> )
                            }
                       
                        </Select>
                    </Grid>
                </Grid>
              
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseDialogAddPlan} variant="contained" size="small" color="secondary" >Cancelar</Button>
                <Button onClick={onSend} variant="contained" size="small" color="primary" >Guardar</Button>
            </DialogActions>

        </Dialog>
    )

}