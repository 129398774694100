import axios from "axios";
import { tokenSesion } from "./constant";

console.log(process.env.REACT_APP_API_URL);
 const AxiosInstance = axios.create({
    baseURL:process.env.REACT_APP_API_URL
});

AxiosInstance.interceptors.request.use(function (config) {
    config.headers["Authorization"] = "Bearer "+localStorage.getItem(tokenSesion);
    // Do something before request is sent
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

export {
    AxiosInstance
}